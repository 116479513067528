// Dataset polyfill from https://github.com/remy/polyfills

;(function () {
  var forEach = [].forEach,
	  regex = /^data-(.+)/,
	  dashChar = /\-([a-z])/ig,
	  el = document.createElement('div'),
	  mutationSupported = false,
	  match
  ;

  function detectMutation() {
	mutationSupported = true;
	this.removeEventListener('DOMAttrModified', detectMutation, false);
  }

  function toCamelCase(s) {
	return s.replace(dashChar, function (m,l) { return l.toUpperCase(); });
  }

  function updateDataset() {
	var dataset = {};
	forEach.call(this.attributes, function(attr) {
	  if (match = attr.name.match(regex))
		dataset[toCamelCase(match[1])] = attr.value;
	});
	return dataset;
  }

  // only add support if the browser doesn't support data-* natively
  if (el.dataset != undefined) return;

  el.addEventListener('DOMAttrModified', detectMutation, false);
  el.setAttribute('foo', 'bar');

  function defineElementGetter (obj, prop, getter) {
	if (Object.defineProperty) {
		Object.defineProperty(obj, prop,{
			get : getter
		});
	} else {
		obj.__defineGetter__(prop, getter);
	}
  }

  defineElementGetter(Element.prototype, 'dataset', mutationSupported
	? function () {
	  if (!this._datasetCache) {
		this._datasetCache = updateDataset.call(this);
	  }
	  return this._datasetCache;
	}
	: updateDataset
  );

  document.addEventListener('DOMAttrModified', function (event) {
	delete event.target._datasetCache;
  }, false);
})();

function slideshow(els, interval = 10000, buttonEls) {

	let currentSlide = -1
	let timeout = null

	els.forEach(el => el.addEventListener('transitionend', function (e) { this.classList.remove('transition') }))

	if (buttonEls) {
		buttonEls.forEach(function (el, i) {
			el.addEventListener('click', function() {
				seek(i)
				clearTimeout(timeout)
				setTimeout(next, 2 * interval)
			})
		})
	}

	function seek(nextSlide) {

		if (els[currentSlide]) {
			els[currentSlide].classList.remove('in')
			els[currentSlide].classList.add('transition')
		}
		els[nextSlide].classList.add('in')
		els[nextSlide].classList.add('transition')

		if (buttonEls) {
			if (buttonEls[currentSlide]) buttonEls[currentSlide].classList.remove('active')
			if (buttonEls[nextSlide]) buttonEls[nextSlide].classList.add('active')
		}

		currentSlide = nextSlide

	}

	function next() {

		if (els.length > 0) {

			seek(currentSlide >= els.length - 1 ? 0 : currentSlide + 1)

		}

		setTimeout(next, interval)

	}

	next()

	return seek

}

let headerImageFrameEl = document.querySelector('.home-header-image-frame')

if (headerImageFrameEl) {

	let headerImageEls = headerImageFrameEl.children

	let headerImages = []

	for (let el of headerImageEls) {

		if (!el.dataset.url) {

			headerImages.push(el)

		} else {

			el.addEventListener('transitionend', function (e) { this.classList.remove('transition') })

			let image = new Image()
			image.onload = e => {
				el.style.backgroundImage = `url(${el.dataset.url})`
				headerImages.push(el)
			}
			image.src = el.dataset.url

		}

	}

	slideshow(headerImages)

}

for (let el of document.querySelectorAll('.slideshow')) {

	let slides = []
	let buttons = false

	for (let childEl of el.children) {

		if (childEl.classList.contains('buttons')) {
			buttons = Array.from(childEl.children)
		} else {
			slides.push(childEl)
		}

	}

	slideshow(slides, el.dataset.interval, buttons)

}

