let containerEl = document.createElement('div')
containerEl.classList.add('modal-container')

function didPressKey (e) {
	
	if (e.key != 'Escape') return
	
	window.hideModal()
	
}

function didClickDocument (e) {
	
	for (let el of containerEl.children) {
		if (el.contains(e.target)) return
	}
	
	window.hideModal()
	
}

window.showModal = function(el) {
	
	el.classList.add('modal')
	
	containerEl.appendChild(el)
	
	document.body.appendChild(containerEl)
	document.body.addEventListener('mousedown', didClickDocument)
	document.body.addEventListener('keydown', didPressKey)
	
	let firstInputEl = el.querySelector('input:not([type="hidden"])')
	if (firstInputEl) firstInputEl.focus()
	
}

window.hideModal = function(el) {
	
	if (el) {
		containerEl.removeChild(el)
	} else {
		containerEl.innerHTML = ''
	}
	
	document.body.removeChild(containerEl)
	document.body.removeEventListener('mousedown', didClickDocument)
	document.body.removeEventListener('keydown', didPressKey)
	
}

function setupModals() {

	let modalEls = {}
	
	for (let el of document.querySelectorAll('[data-modal]')) {
		
		let modalEl = modalEls[el.dataset.modal] || document.getElementById(el.dataset.modal)
		modalEl.parentNode.removeChild(modalEl)
		
		if (modalEl.parentNode) {
			modalEls[el.dataset.modal] = modalEl
			modalEl.parentNode.removeChild(modalEl)
		}
		
		el.addEventListener('click', () => {
			window.showModal(modalEl)
		})
		
	}

}

setupModals()